export enum HttpApi {
  IsDomainExist = '/sasm/domainprefix/check'
}
export enum HttpAccountApi {
  SaveAccountInfo = '/gp/account/save',
  CheckEmailValid = '/gp/account/check-email-valid', // n
  SendVerifyMail = '/gp/account/send-verify-mail', // n
  VerifyMailCode = '/gp/account/verify-mail-code', // n
  VerifyMailLink = '/gp/account/verify-mail-link', // n
  SetPassword = '/gp/account/set-password',
  SetUsername = '/gp/account/update-user-name',
  SetTimeZone = '/gp/account/update-time-zone',
  SetAvatar = '/gp/account/update-user-avatar-url',
  GetLoginEquipment = '/gp/account/query-login-equipment',
  LogoutDevice = '/gp/account/logout',
  LogoutAllDevices = '/gp/account/logout-all',
  LogoutAllTenant = '/gp/account/logout-all-tenant', // n
  UploadFile = '/gp/account/upload-account-avatar',
  SendVerifyCodeMail = '/gp/account/send-verify-code-mail',
  CheckVerifyCodeMail = '/gp/account/check-verify-code-mail',
  SetPasswordSkip = '/gp/account/set-is-skip-password',
  SendResetMail = '/gp/account/send-reset-mail', // n
  CheckResetToken = '/gp/account/check-reset-token', // n
  ResetPassword = '/gp/account/reset-password', // n
  GetWorkspaceAccountInfo = '/gp/account/info',
  checkAccountAccess = '/tenants/account-roles',
  MfaAssistanceEmail = '/gp/account/mfa-assistance-emails', // n
  VerifyLinkCode = '/gp/account/verify-link-code', // n
  VerifyPassword = '/gp/account/verify-password' // n
}

export enum HttpStaffApi {
  VerifyInviteCode = '/gp/staff/verify-invite-code', // n
  GetShareableLink = '/gp/staff/get-shareable-link',
  SendInviteEmail = '/gp/staff/send-invite-mail', // n
  SaveStaff = '/gp/staff/save-staff-info', // n
  ActiveStaff = '/gp/staff/active' // n
}

export enum HttpSignInApi {
  LoginCheck = '/gp/account/login-check',
  SendLoginMail = '/gp/account/send-login-mail', // n
  LoginWithCode = '/gp/account/login-by-code', // n
  LoginWithPassword = '/gp/account/login-by-password', // n
  VerifyLoginMailLink = '/gp/account/login-with-link', // n
  RefreshToken = '/gp/account/get-new-tokens', // n
  Session = '/gp/account/session' // n
}

export enum HttpMemberApi {
  Deactivate = '/gp/account/deactivate',
  Export = '/gp/account/export',
  PageList = '/gp/account/page',
  Role = '/permission-gp/manage/update-role',
  Query = '/permission-gp/manage/query-role',
  Ownership = '/permission-gp/manage/transfer-ownership',
  SendVerifyCodeMail = '/gp/account/send-verify-code-mail',
  CheckVerifyCodeMail = '/gp/account/check-verify-code-mail'
}

export enum HttpTenantApi {
  UpdateWorkspaceLogo = '/gp/tenant/update-workspace-logo',
  GetTenantInfo = '/gp/tenant/get-workspace-info',
  UpdateWorkspaceName = '/gp/tenant/update-workspace-name',
  UpdateDomainPrefix = '/sasm/domainprefix/update-domain-prefix',
  DeleteDomainPrefix = '/gp/tenant/delete-workspace',
  GetTenantTaskCount = '/gp/tenant/tenant-task-count'
}

export enum HttpDriveApi {
  GetFullUrlByHash = '/sasm/domainprefix/get-full-url-by-hash' // n
}

export enum HttpBrandingApi {
  UploadIconFile = '/gp/account/upload-icon-file',
  UploadFile = '/gp/account/upload-file',
  GetTenantBranding = '/gp/tenant/get-tenant-branding',
  SaveTenantBranding = '/gp/tenant/save-tenant-branding',
  ConfigLanguageList = '/gp/internationalization/config-language-list',
  QueryByType = '/sasm/common-param/query-by-type'
}

export enum RegistrationApi {
  QueryRegistration = '/gp/tenant/query-registration',
  UpdateRegistration = '/gp/tenant/save-registration'
}

export enum ClientApi {
  TenantInfo = '/gp/client/tenant-info' // n
}
export enum BizOpsApi {
  querySales = '/gp/account/by-roles',
  addBizOpsRoleAccount = '/gp/tenant/biz-ops-account',
  getInternalList = '/gp/account/get-account-infos',
  putTenantSettings = '/gp/tenant/:tenantId'
}

export enum ContractApi {
  contractDetail = '/v2/tenants/:tenantId/contracts/:id',
  contractImages = '/gp/contract/:id/images',
  updateContract = '/v2/tenants/:tenantId/contracts/:id',
  updateFile = '/gp/contract/:id/file',
  getContractList = '/gp/contracts',
  addContract = '/v2/tenants/:tenantId/contracts',
  DeleteContract = '/v2/tenants/:tenantId/contracts/:id',
  getContractInfo = '/gp/contract',
  exportContract = '/gp/contracts/export',
  importContract = '/gp/contracts/import',

  analysesContract = '/gp/contracts/:contractId/forms',
  getAnalysesContractForm = '/gp/contracts/:contractId/forms/:formId',
  getLatestFormJSON = '/gp/contracts/:contractId/forms',
  createSmartForm = '/gp/contracts/:contractId/forms/:formId/smart-forms',
  getSmartForm = '/gp/contracts/:contractId/smart-forms/:smartFormId'
}

export enum DashboardApi {
  GetWorkspaces = '/gp/tenant/workspaces',
  GetWorkspaceDetail = '/gp/tenant/workspace'
}

export enum FeatureApi {
  Features = '/tenants/:tenantId/features',
  ToggleFeature = '/tenants/:tenantId/features/:featureId'
}

export enum EmailConfigApi {
  GetDomainSetting = '/gp/:tenantId/email/domain/setting',
  UpdateDomainSetting = '/gp/:tenantId/email/domain/setting',
  GetDomains = '/gp/:tenantId/email/domains',
  GetDomainsDefault = '/gp/:tenantId/email/domains/default',
  GetDomainDetail = '/gp/:tenantId/email/domains/:domainId',
  DomainVerify = '/gp/:tenantId/email/domains/:domainId/validate',
  AddDomain = '/gp/:tenantId/email/domains',
  deleteDomain = '/gp/:tenantId/email/domains/:domainId'
}

export enum CustomDomainApi {
  GetDomainSetting = '/sasm/domainprefix/get-domain-info-by-tenantId',
  UpdateDomainSetting = '/sasm/domainprefix/custom-domain-flag',
  GetDomains = '/sasm/custom-domains',
  AddDomain = '/sasm/custom-domains',
  DomainVerify = '/sasm/custom-domains/verify',
  DeleteDomain = '/sasm/custom-domains'
}

export enum WorkspaceApi {
  getWorkspaces = '/gp/workspaces',
  getWorkspace = '/gp/workspaces/:tenantId',
  getWorkspaceToken = '/gp/workspaces/:tenantId/token',
  getClusterStatistics = '/gp/workspace/statistics'
}

export enum AuthApi {
  createToken = '/v2/tenants/:tenantId/api-tokens',
  getTokens = '/v2/tenants/:tenantId/api-tokens',
  getAPIRoles = '/v2/tenants/:tenantId/api-roles',
  updateToken = '/v2/tenants/:tenantId/api-tokens/:id'
}

export enum IFAApi {
  IFASettings = '/tenants/:tenantId/ifa-settings'
}

export enum LiteMemberApi {
  GetLiteMemberSetting = '/workspaces/:tenantId/configurations/gp-members',
  PutLiteMemberSetting = '/workspaces/:tenantId/configurations/gp-members/:id'
}
